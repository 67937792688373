import React, {useState, useEffect} from 'react';
import {
    HamburgerIcon,
    Nav,
    NavbarContainer,
    NavBtnLink,
    NavItem,
    NavItemBtn,
    NavLinks,
    NavMenu
} from './Navbar.elements';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { Button } from '../../globalStyles';

function Navbar({ isVisible }) {
    const [click, setClick] = React.useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
      const checkMobileDevice = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const isMobileDevice = /android|ipad|iphone|ipod|kindle|windows phone|blackberry/i.test(userAgent.toLowerCase());
        setIsMobile(isMobileDevice);
      };
  
      checkMobileDevice();
    }, []);


    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <Nav>
                    <NavbarContainer>
                        {isMobile && 
                            (<>
                                <HamburgerIcon onClick={handleClick}>
                                    {click ? <FaTimes color='#D6FF72' /> : <FaBars color='#D6FF72' />}
                                </HamburgerIcon>
                                {!isVisible && !click &&
                                    <NavBtnLink to='/sign-up'>
                                        <Button primary>SIGN UP</Button>
                                    </NavBtnLink>}
                                <NavMenu onClick={handleClick} click={click}>
                                    <NavItem>
                                        <NavLinks href='/#plans' onClick={closeMobileMenu}>
                                            PLANS
                                        </NavLinks>
                                    </NavItem>
                                    <NavItem>
                                        <NavLinks href='/#how-it-works' onClick={closeMobileMenu}>
                                            HOW IT WORKS
                                        </NavLinks>
                                    </NavItem>
                                    <NavItem>
                                        <NavLinks href='/#our-mission' onClick={closeMobileMenu}>
                                            OUR MISSION
                                        </NavLinks>
                                    </NavItem>
                                    <NavItemBtn>
                                        <NavBtnLink to='/sign-up'>
                                            <Button primary>SIGN UP</Button>
                                        </NavBtnLink>
                                    </NavItemBtn>
                                </NavMenu>
                                </>
                            )
                        }
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    );
}

export default Navbar;
