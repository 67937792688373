import React, { useState, useEffect } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import GlobalStyles from './globalStyles'
import {Footer, Navbar, Plans, InfoSection} from './components';
import SignUp from './pages/SignUp/SignUp';
import OurMission from "./pages/OurMission/OurMission";
import HowItWorks from "./pages/HowItWorks/HowItWorks";
import ScrollToTop from './components/ScrollToTop';
import Home from "./pages/HomePage/Home";
import "@fillout/react/style.css"

function App() {
    const [isSignUpVisible, setIsSignUpVisible] = React.useState(false)

    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
      const checkMobileDevice = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const isMobileDevice = /android|ipad|iphone|ipod|kindle|windows phone|blackberry/i.test(userAgent.toLowerCase());
        setIsMobile(isMobileDevice);
      };
  
      checkMobileDevice();
    }, []);

    console.log(isMobile)

    return (
        <Router>
            <GlobalStyles/>
            <ScrollToTop/>
            <Navbar isVisible={isSignUpVisible} />
            <Switch>
            {isMobile ? (
                <>
                    <Route path="/" exact>
                    <Home onSignUpVisible={setIsSignUpVisible} />
                    </Route>
                    <Route path="/plans" component={Plans} />
                    <Route path="/InfoSection" component={InfoSection} />
                    <Route path="/HowItWorks" component={HowItWorks} />
                    <Route path="/OurMission" component={OurMission} />
                    <Route path="/sign-up" component={SignUp} />
                </>
                ) : (
                    <Route path="/" >
                        <Plans/> 
                    </Route> 
                )
            }
            </Switch>
            <Footer/>
        </Router>
    );
}


export default App;
