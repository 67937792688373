import styled from 'styled-components';


export const InfoSec = styled.div`
    color: #fff;
    padding: 160px 0;
    background: #000000;
`

export const InfoRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse' : 'row')};
`;

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: ${({hasImage}) => (hasImage ? '50%' : '100%')};
  flex-basis: ${({hasImage}) => (hasImage ? '50%' : '100%')};

  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  max-width:  ${({hasImage}) => (hasImage ? '540px' : '100%')};
  padding-top: 0;
  padding-bottom: 60px;
  text-align: center; // Center all text inside

  @media (min-width: 480px) and (max-width: 1200px) {
    padding-bottom: 65px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding-bottom: 65px;
  }
`;


export const ImgWrapper = styled.div`
  max-width:  ${({hasImage}) => (hasImage ? '540px' : '100%')};
  display: flex;
  justify-content: ${({start}) => (start ? 'flex-start' : 'flex-end')};
`;

export const TopLine = styled.div`
  color: #ffffff;
  font-size: 24px;
  line-height: 53px;
  letter-spacing: 20px;
  font-weight: 300;
  margin-bottom: 126px;
  text-align: center; // Center text


  @media (min-width: 1024px) {
    font-size: 24px; // Large screens
  }

  @media (max-width: 1024px) {
    font-size: 24px; // Medium screens
  }

  @media (max-width: 768px) {
    font-size: 96px; // Small screens (tablets)
  }

  @media (max-width: 480px) {
    font-size: 24px; // Extra small screens (phones)
  }
`;


export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
`;

export const Heading = styled.h1`
  margin-bottom: 44px;
  color: #ffffff;
  text-align: center; // Center text
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: bold;
  font-variation-settings:
    "slnt" 0;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 13px;
`;


export const Subtitle = styled.p`
  max-width:  ${({hasImage}) => (hasImage ? '540px' : '100%')};
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 13px;
  color: #ffffff;
  text-align: center; // Center text
  padding-top: 44px;
  width: 1200px; // Default width
  margin-bottom: 60px; // Spacing between the subtitle and the row of columns
  word-wrap: break-word; // Ensure words do not overflow the width

  // Media query for mobile devices
  @media (max-width: 768px) {
    width: 100%; // Set width to 100% on small screens
    padding: 0 20px; // Optional: Add padding for better spacing on small screens
  }
`;

