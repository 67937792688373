import React from 'react';
import {InfoSection, Plans} from '../../components';
import {homeObjOne} from './Data';
import OurMission from "../OurMission/OurMission";
import HowItWorks from "../HowItWorks/HowItWorks";

const Home = ({onSignUpVisible}) => {
    return (
        <>
            <InfoSection id="home" {...homeObjOne} onSignUpVisible={onSignUpVisible} />
            <Plans id="plans"/>
            <HowItWorks id="how-it-works"/>
            <OurMission id="our-mission"/>
        </>
    )
}

export default Home;
