import React from 'react';
import RowSection from "../../components/RowSection/RowSection";
import hamburger from "../../images/foodie.png"
import calendar from "../../images/heartCalendar.png"
import arrows from "../../images/arrowsYellow.png"
import kiss from "../../images/smileStars.png"

const OurMission = ({id}) => {
    const rows = [
        {
            text: 'ELEVATED DINING EXPERIENCE BASED ON YOUR PREFERENCES',
            image: arrows,
        },
        {
            text: 'MAKE TIME FOR YOUR LOVED ONES WITH A RESERVED DEDICATED TIME ON YOUR CALENDAR',
            image: kiss,
        },
        {
            text: 'LET US ADD EXCITEMENT INTO YOUR ROUTINE AND HAVE SOMETHING TO LOOK FORWARD TO ON YOUR CALENDAR',
            image: calendar,
        },
        {
            text: 'DISCOVER A CURATED SELECTION OF HANDPICKED RESTAURANTS IN YOUR CITY, ENSURING EACH OUTING IS UNIQUE',
            image: hamburger,
        },
    ];

// In your render function or return statement
    return (<RowSection
        lightBg={true}
        lightText={false}
        lightTextDesc={false}
        rows={rows}
        id={id}
        sectionTitle="OUR MISSION"
    />)
}

export default OurMission;
