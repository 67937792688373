export const homeObjOne = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'BLINDINNER',
    headline: 'AUTOMATED DINING AND QUALITY SCHEDULING TAILORED FOR YOU',
    description: '',
    buttonLabel_first: 'SIGN UP',
    imgStart: '',
    linkTo_first: 'sign-up',
    img: require('../../images/svg-1.svg'),
    alt: 'Credit Card',
    start: ''
  };
