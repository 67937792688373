import React from 'react';
import {
    Column,
    Image,
    ImageWrapper,
    Row,
    RowSec,
    SectionSubtitle,
    SectionTitle,
    Text,
    Title
} from './RowSection.elements';

const RowSection = ({
                        lightBg,
                        lightText,
                        lightTextDesc,
                        rows,
                        id,
                        sectionTitle,
                        sectionSubtitle
                    }) => {
    return (
        <RowSec lightBg={lightBg} id={id}>
            <SectionTitle lightText={lightText}>{sectionTitle}</SectionTitle>
            <SectionSubtitle lightTextDesc={lightTextDesc}>{sectionSubtitle}</SectionSubtitle>
            <Row>
                {rows.map((item, index) => (
                    <Column key={index}>
                        {item.image && (
                            <ImageWrapper>
                                <Image src={item.image} alt={item.imgAlt || 'Column image'}/>
                            </ImageWrapper>
                        )}
                        <Title lightText={lightText}>{item.title}</Title>
                        <Text lightTextDesc={lightTextDesc}>{item.text}</Text>
                    </Column>
                ))}
            </Row>
        </RowSec>
    );
};

export default RowSection;
