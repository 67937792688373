import React from 'react';
import {
    FooterContainer,
    FooterLink,
    FooterLinkItems,
    FooterLinksContainer,
    FooterLinksWrapper,
    SocialMedia,
} from './Footer.elements';

function Footer() {

    return (
        <FooterContainer>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLink href="mailto:ny.resturant@gmail.com">Contact Us @BLINDINNER</FooterLink>
                    </FooterLinkItems>
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
                {/*<SocialMediaWrap>*/}
                {/*    <SocialLogo to='/'>*/}
                {/*        <SocialIcon/>*/}
                {/*        BlinDinner*/}
                {/*    </SocialLogo>*/}
                {/*    <WebsiteRights>Blindinner.com © {date.getFullYear()} </WebsiteRights>*/}
                {/*    /!* <SocialIcons>*/}
                {/*        <SocialIconLink href='/' target='_blank' aria-label='Facebook'>*/}
                {/*            <FaFacebook/>*/}
                {/*        </SocialIconLink>*/}
                {/*        <SocialIconLink href='/' target='_blank' aria-label='Instagram'>*/}
                {/*            <FaInstagram/>*/}
                {/*        </SocialIconLink>*/}
                {/*        <SocialIconLink href='/' target='_blank' aria-label='YouTube'>*/}
                {/*            <FaYoutube/>*/}
                {/*        </SocialIconLink>*/}
                {/*        <SocialIconLink href='/' target='_blank' aria-label='Twitter'>*/}
                {/*            <FaTwitter/>*/}
                {/*        </SocialIconLink>*/}
                {/*        <SocialIconLink href='/' target='_blank' aria-label='LinkedIn'>*/}
                {/*            <FaLinkedin/>*/}
                {/*        </SocialIconLink>*/}
                {/*    </SocialIcons> *!/*/}
                {/*</SocialMediaWrap>*/}
            </SocialMedia>
        </FooterContainer>
    );
}

export default Footer;
