import React, { useRef, useEffect } from 'react';
import {
    Heading,
    Img,
    ImgWrapper,
    InfoColumn,
    InfoRow,
    InfoSec,
    TextWrapper,
    TopLine
} from './InfoSection.elements';
import { Button, Container } from '../../globalStyles';
import { Link } from 'react-router-dom';

const InfoSection = ({
                         primary,
                         lightBg,
                         topLine,
                         lightTopLine,
                         lightText,
                         headline,
                         buttonLabel_first,
                         buttonLabel_second,
                         img,
                         alt,
                         imgStart,
                         start,
                         linkTo_first,
                         linkTo_second,
                         id,
                         hasImage = false,
                         onSignUpVisible,
                     }) => {
    const buttonRef = useRef(null);
    const handleScroll = () => {
        if (buttonRef.current) {
            const buttonVisible = isButtonVisible(buttonRef.current);
            onSignUpVisible(buttonVisible);
        }
    };

    const isButtonVisible = (buttonElement) => {
        const rect = buttonElement.getBoundingClientRect();
        const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line
    }, []);


    return (
        <>
            <InfoSec id={id} lightBg={lightBg}>
                <Container>
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn hasImage={hasImage}>
                            <TextWrapper hasImage={hasImage}>
                                {topLine && <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>}
                                <Heading lightText={lightText}>{headline}</Heading>
                                {buttonLabel_first && (
                                    <Link to={linkTo_first}>
                                        <Button ref={buttonRef} big fontBig primary={primary}>
                                            {buttonLabel_first}
                                        </Button>
                                    </Link>
                                )}
                                {linkTo_second && (
                                    <Link to={linkTo_second}>
                                        <Button big fontBig primary={primary}>
                                            {buttonLabel_second}
                                        </Button>
                                    </Link>
                                )}
                            </TextWrapper>
                        </InfoColumn>
                        {hasImage && (
                            <InfoColumn>
                                <ImgWrapper hasImage={hasImage} start={start}>
                                    <Img src={img} alt={alt} />
                                </ImgWrapper>
                            </InfoColumn>
                        )}
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    );
};

export default InfoSection;
