import React from 'react';
import RowSection from "../../components/RowSection/RowSection";
import preferences from "../../images/preferences.png"
import calendarTic from "../../images/calendarTic.png"
import heart from "../../images/heart.png"


const HowItWorks = ({id}) => {
    const rows = [
        {
            text: 'SHARE YOUR SCHEDULE PREFERENCES AND FAVORITE RESTAURANTS FOR YOUR PLANS',
            image: preferences
        },
        {
            text: 'GET CALENDAR INVITES WITH YOUR BOOKING DETAILS AND LOOK FORWARD TO QUALITY TIME',
            image: calendarTic
        },
        {
            text: 'DINE AT A CURATED RESTAURANT, MAKE NEW MEMORIES, AND CHERISH SHARED EXPERIENCES',
            image: heart
        },
    ];

// In your render function or return statement
    return (<RowSection
        lightBg={false}
        lightText={true}
        lightTextDesc={true}
        rows={rows}
        id={id}
        sectionTitle="HOW IT WORKS"
    />)
}

export default HowItWorks;
