import styled from 'styled-components';

export const RowSec = styled.div`
  color: #fff;
  padding: 70px 0;
  width: auto;
  background: #000000;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  padding: 0 0px; // Container padding of 50 pixels
  @media (max-width: 768px) {
    padding: 0 0px; // Optional: Add padding for better spacing on small screens
  }
`;

export const Column = styled.div`
  margin-bottom: 100px;
  padding: 0 30px; // Increased padding to 50px on left and right
  flex: 0 0 25%; // This ensures that the flex-basis doesn't grow beyond 25%
  max-width: 25%; // Each column takes up 25% of the width for 4 columns
  text-align: center; // Centers the text within each column
  flex-direction: column; // Stacks children vertically
  justify-content: center; // Centers children along the cross axis
  align-items: center; // Centers children along the main axis

  @media screen and (max-width: 960px) {
    max-width: 25%;
    flex: 0 0 50%; // Adjust the flex-basis for responsive design
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex: 0 0 100%; // Adjust the flex-basis for smaller screens
  }

  // Here we add media queries to ensure the text size adjusts for responsiveness
  @media screen and (max-width: 600px) {
    font-size: 14px; // Smaller text for very small screens
  }

  @media screen and (min-width: 601px) and (max-width: 960px) {
    font-size: 16px; // Slightly larger text for medium screens
  }

`;

export const Title = styled.h2`
color: #ffffff;
text-align: center; // Center text
font-optical-sizing: auto;
font-weight: 300;
font-style: bold;
font-variation-settings:
  "slnt" 0;
font-size: 24px;
line-height: 23px;
letter-spacing: 13px;
`;

export const Text = styled.p`
color: #ffffff;
text-align: center; // Center text
font-optical-sizing: auto;
font-weight: 300;
font-style: bold;
font-variation-settings:
  "slnt" 0;
font-size: 12px;
line-height: 23px;
letter-spacing: 13px;
`;

export const ImageWrapper = styled.div`
  margin-bottom: 24px; // Spacing between image and title
`;

export const Image = styled.img`
  max-width: 100%; // Ensures the image is responsive and fits the column
  height: auto; // Keeps the aspect ratio of the image
  margin-left: auto; // Center the image in the column
  margin-right: auto;
  width: 150px; // Fixed width, you might want to make this responsive as well
`;

export const SectionTitle = styled.h1`
color: #ffffff;
font-size: 24px;
line-height: 53px;
letter-spacing: 26px;
font-weight: 300;
margin-bottom: 126px;
text-align: center; // Center text
padding-bottom: 10px; // Spacing between the title and subtitle
`;

export const SectionSubtitle = styled.h2`
  font-size: 20px; // Size of the section subtitle
  color: #ffffff;
  text-align: center;
  width: 100%; // Default width
  margin: 0 auto; // Center the subtitle within its container
  margin-bottom: 60px; // Spacing between the subtitle and the row of columns
  word-wrap: break-word; // Ensure words do not overflow the width

  // Media query for mobile devices
  @media (max-width: 768px) {
    width: 100%; // Set width to 100% on small screens
    padding: 0 20px; // Optional: Add padding for better spacing on small screens
  }
`;
