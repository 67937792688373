import styled, {createGlobalStyle} from 'styled-components';

const GlobalStyles = createGlobalStyle`
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Inter;
}
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}
*::-webkit-scrollbar {
  width: 0.5px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}
`;


export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const Button = styled.button`
  border-radius: 4px;
  background: #D6FF72;
  white-space: nowrap;
  padding: ${({big}) => (big ? '12px 32px' : '10px 20px')};
  color: #000000;
  font-size: 12px;
  line-height:23px;
  letter-spacing:6px;
  outline: none;
  border: none;
  text-align: center; // Center text
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: bold;
  font-variation-settings:
    "slnt" 0;

  

  cursor: pointer;
  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: #FD8DF6;
  }
  margin-bottom: 10px; /* Or whatever space you want between the buttons */
`;


export default GlobalStyles;
