import React from 'react';
import {IconContext} from 'react-icons/lib';
import {
    PlansCard,
    PlansCardCost,
    PlansCardInfo,
    PlansCardPlan,
    PlansContainer,
    PlansHeading,
    PlansSection,
    PlansWrapper
} from './Plans.elements';
import {Button} from '../../globalStyles'

const Plans = ({id}) => {
    return (
        <IconContext.Provider value={{color: '#a9b3c1', size: 64}}>
            <PlansSection id={id}>
                <PlansWrapper>
                    <PlansHeading>PLANS</PlansHeading>
                    <PlansContainer>
                        <PlansCard to='/sign-up?explorer'>
                            <PlansCardInfo>
                                <PlansCardPlan>MAGIC</PlansCardPlan>
                                <PlansCardCost>EVERY WEEK </PlansCardCost>
                                <Button>SELECT</Button>
                                <PlansCardCost> SAVE 25%</PlansCardCost>

                            </PlansCardInfo>
                        </PlansCard>
                        <PlansCard to='/sign-up?plus'>
                            <PlansCardInfo>
                                <PlansCardPlan>BASIC</PlansCardPlan>
                                <PlansCardCost>EVERY OTHER <br /> WEEK</PlansCardCost>
                                <Button>SELECT</Button>
                            </PlansCardInfo>
                        </PlansCard>
                    </PlansContainer>
                </PlansWrapper>
            </PlansSection>
        </IconContext.Provider>
    );
}

export default Plans
