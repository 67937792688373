import {Link} from 'react-router-dom';
import styled from 'styled-components';

export const PlansSection = styled.div`
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #000000;
`;

export const PlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const PlansHeading = styled.h1`
  color: #ffffff;
  font-size: 24px;
  line-height: 53px;
  letter-spacing: 26px;
  font-weight: 300;
  margin-bottom: 126px;
  text-align: center; // Center text
  padding: 100px,0;
`;

export const PlansContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-color : #D6FF72;
  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const PlansCard = styled(Link)`
  background: #ffffff
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  width: 280px;
  height: 280px;
  margin-right: 70px;
  text-decoration: none;
  border-radius: 4px;
  &:nth-child(1) { /* First card */
    margin-right: 24px; /* Adjust margin for the first card */
    border: solid;
    border-width: 4px;
    border-color : #D6FF72;
  }

  &:nth-child(2) { /* Second card */
    background: #ffffff
    margin-right: 24px; /* Adjust margin for the second card */
    border: solid;
    border-width: 4px;
    border-color : #D6FF72;
  }

  &:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
  }
  @media screen and (max-width: 960px) {
    width: 90%;
    margin-right: 0; /* Remove the right margin on mobile */
    margin-bottom: 32px; /* Add margin between the cards vertically on mobile */
    &:nth-child(1),
    &:nth-child(2) {
      margin-right: 0; /* Remove individual card margins on mobile */
    }
  }
    &:hover {
      transform: none;
    }
  }
`;

export const PlansCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  padding: 24px;
  align-items: center;
  color: #fff;
`;

export const PlansCardIcon = styled.div`
  margin: 24px 0;
`;

export const PlansCardPlan = styled.h3`
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: 300;
  font-style: bold:
  font-spacing: 13px;
  line-height: 23px;
  letter-spacing: 13px;
`;

export const PlansCardModel = styled.h3`
  font-size: 24px;
  margin-bottom: 24px;
`;

export const PlansCardCost = styled.h4`
  font-size: 12px;
  font-weight: 300;
  font-style: bold:
  font-spacing: 13px;
  line-height: 23px;
  letter-spacing: 13px;
  padding: 20px;
  text-align: center;
`;

export const PlansCardLength = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

export const PlansCardFeatures = styled.ul`
  margin: 16px 0 32px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a9b3c1;
`;

export const PlansCardFeature = styled.li`
  margin-bottom: 10px;
`;

export const Button = styled.button`
  border-radius: 4px;
  background: #ffffff;
  white-space: nowrap;
  padding: ${({big}) => (big ? '12px 32px' : '10px 20px')};
  color: #000000;
  font-size: 12px;
  line-height:23px;
  letter-spacing:13px;
  outline: none;
  border: none;
  text-align: center; // Center text
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: bold;
  font-variation-settings:
    "slnt" 0;

  

  cursor: pointer;
  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: #FD8DF6;
  }
  margin-bottom: 10px; /* Or whatever space you want between the buttons */
`;